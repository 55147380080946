<template>
  <div class="partners">
    <div class="container">
      <div class="row">
        <partner
          v-for="(item, index) in partners"
          :partner="item"
          :index="index"
          :key="index"
          @modal="showModal($event)"
        />
      </div>
    </div>
    <b-modal id="partners-modal" hide-header hide-footer centered scrollable-y>
      <div class="popup details-modal partners-modal">
        <div class="row">
          <div class="col-12">
            <div class="details-modal__header mb-2">
              <img :src="activePartner.image" class="partners-modal__image" />
              <div class="partners-modal__title">
                {{ activePartner.title }}
              </div>
            </div>
            <div
              class="details-modal__body editor"
              v-html="activePartner.description"
            ></div>
            <div class="partners-modal__link">
              <div class="partners-modal__link">
                <b-icon icon="globe"></b-icon>
                <a
                  :href="activePartner.url"
                  target="blank"
                  v-if="activePartner.url"
                >
                  {{ getUrl(activePartner.url) }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="popup__close" @click="$bvModal.hide('partners-modal')">
          <b-icon :icon="'x'"></b-icon>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Partner from "./Partner.vue";
export default {
  components: { Partner },
  data() {
    return {
      active: null,
    };
  },
  computed: {
    ...mapState({
      partners: (state) => state.partners.partners,
    }),
    activePartner() {
      if (this.active != null) {
        return this.partners[this.active];
      }
      return {};
    },
  },
  methods: {
    showModal(e) {
      this.active = e;
      this.$bvModal.show("partners-modal");
    },
    getUrl(e) {
      if (e.indexOf("//") !== -1) return e.split("//")[1];
      else return e;
    },
  },
};
</script>