<template>
  <div class="col-lg-4 col-md-6">
    <div class="team-item partner" @click="openModal">
      <div class="team-item__inner partner__inner">
        <div class="partner__image">
          <img :src="partner.image" />
        </div>
        <div class="partner__name">
          {{ partner.title }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    partner: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    openModal() {
      this.$emit("modal", this.index);
    },
  },
};
</script>