<template>
  <div class="faq">
    <page-cover :cover="cover"  v-if="cover" />
    <PartnersPage />
  </div>
</template>
<script>
import PageCover from "@/components/shared/PageCover.vue";
import PartnersPage from "../../components/partners/PartnersPage.vue";
import { mapGetters } from "vuex";
export default {
  name: "partners",
  computed: {
    ...mapGetters({
      cover: "pageCover/getPartners",
    }),
  },
  created() {
    this.$store.dispatch("partners/getPartners");
  },
  components: {
    PageCover,
    PartnersPage,
  },
};
</script>